<script setup lang="ts"></script>

<template>
    <svg
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M65.3251 40.569C65.7675 38.4488 66 36.2515 66 34C66 31.7485 65.7675 29.5512 65.3251 27.431L67.2829 27.0225C67.7532 29.2763 68 31.6104 68 34C68 36.3896 67.7532 38.7237 67.2829 40.9775L65.3251 40.569ZM40.569 2.6749L40.9775 0.717057C38.7237 0.246827 36.3896 0 34 0C31.6104 0 29.2763 0.246828 27.0225 0.717057L27.431 2.6749C29.5512 2.23253 31.7485 2 34 2C36.2515 2 38.4488 2.23253 40.569 2.6749ZM24.024 3.58562L23.4011 1.68512C18.8801 3.16711 14.7789 5.57018 11.3187 8.67058L12.6534 10.1601C15.9114 7.24085 19.7713 4.97968 24.024 3.58562ZM10.1601 12.6534L8.67058 11.3187C5.57018 14.7789 3.16711 18.8801 1.68512 23.4011L3.58562 24.0241C4.97968 19.7713 7.24085 15.9114 10.1601 12.6534ZM2.6749 27.431C2.23253 29.5512 2 31.7485 2 34C2 36.2515 2.23253 38.4488 2.6749 40.569L0.717057 40.9775C0.246828 38.7237 0 36.3896 0 34C0 31.6104 0.246827 29.2763 0.717057 27.0225L2.6749 27.431ZM3.58562 43.976L1.68512 44.5989C3.16711 49.1199 5.57018 53.2211 8.67058 56.6813L10.1601 55.3466C7.24085 52.0886 4.97968 48.2287 3.58562 43.976ZM12.6534 57.8399L11.3187 59.3294C14.7789 62.4298 18.8801 64.8329 23.4011 66.3149L24.0241 64.4144C19.7713 63.0203 15.9114 60.7592 12.6534 57.8399ZM27.431 65.3251L27.0225 67.2829C29.2763 67.7532 31.6104 68 34 68C36.3896 68 38.7237 67.7532 40.9775 67.2829L40.569 65.3251C38.4488 65.7675 36.2515 66 34 66C31.7485 66 29.5512 65.7675 27.431 65.3251ZM43.976 64.4144L44.5989 66.3149C49.1199 64.8329 53.2211 62.4298 56.6813 59.3294L55.3466 57.8399C52.0886 60.7591 48.2287 63.0203 43.976 64.4144ZM57.8399 55.3466L59.3294 56.6813C62.4298 53.2211 64.8329 49.1199 66.3149 44.5989L64.4144 43.9759C63.0203 48.2287 60.7592 52.0886 57.8399 55.3466Z"
            fill="currentColor"
        />
    </svg>

    <!-- <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
        <rect
            width="80"
            height="60"
            x="10"
            y="20"
            stroke="black"
            stroke-width="3"
            fill="transparent"
            stroke-dasharray="5 3"
        />
    </svg> -->

    <!-- <svg
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="34"
            cy="34"
            r="33"
            stroke="currentColor"
            stroke-width="3"
            fill="transparent"
            stroke-dasharray="5 3"
        />
    </svg> -->
</template>

<style>
/* circle {
    stroke-dasharray: 12 4;
} */
</style>
